<template>
	<div class="ownProjectType">
		<div class="ownProjectType-container">
			<div class="container-left">
				<div class="account-container">
					<div class="companyName">
						<div class="name">
							<p class="text-left">{{teamSpace?'公司名称':'空间名称'}}</p>
						</div>
						<div class="fun">
							<p class="text-right" style="padding-top: 0px;">{{spaceInfo.name}}
							</p>
							<el-button style="margin-top: 20px;" type="primary"  @click="editName" v-if="isEdit">
								变更
							</el-button>
						</div>
					</div>
					<div class="item" style="padding: 30px 0;">
						<div class="item-right">
							<p class="text-left">服务到期时间</p>
						</div>
						<div class="item-left">
							<p class="text-right disable"  v-if="spaceInfo.expireTime">{{spaceInfo.expireTime.slice(0,10)}}</p>
						</div>
					</div>
				</div>
				<div class="storage-container">
					<div class="text-left">存储空间</div>
					<div class="text-right disable">无限制</div>
				</div>
				<div v-if="teamSpace" class="num-container">
					<span class="text-left">席位</span>
					<span class="text-right disable">{{spaceInfo.numberAccount}}</span>
				</div>
			</div>
			<PersonnelList :isEdit="isEdit"  :count="count" :teamSpace="teamSpace"></PersonnelList>
		</div>
		<el-dialog :visible.sync="editDialog" :close-on-click-modal="false" title="上传logo">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="企业logo" style="text-align:left">
					<el-upload ref="uploadAdd" :show-file-list="false" action list-type="picture-card" :http-request="uploadPicture" :on-change="getPicture" accept=".bmp,.jpg,.png,.jpeg,.BMP,.JPG,.PNG,.JPEG">
						<el-image v-if="PictureUrlService" style="width: 130px; height: 60px" :src="PictureUrlService" fit="cover"></el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<i style="color: red;">注：请上传宽130px,高60px的图片，否则将被自动裁剪</i>
			</el-form>
			<div style="text-align:right;" class="btn">
				<el-button type="danger" @click="editDialog = false">取消</el-button>
				<el-button type="primary" @click="changeImg">确定</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="editNameDialog" :close-on-click-modal="false" title="编辑">
			<el-form label-width="80px" label-position="left">
				<el-form-item label="企业名称" style="text-align:left">
					<el-input v-model="name" placeholder="用户名称" />
				</el-form-item>
			</el-form>
			<div style="text-align:right;" class="btn">
				<el-button type="danger" @click="editNameDialog = false">取消</el-button>
				<el-button type="primary" @click="UpdateSpaceName">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
  mapState,
  mapMutations
} from 'vuex'
import TopBar from '@/components/layout/TopBar.vue'
import PersonnelList from '@/components/PersonnelList.vue'
var formData = new FormData()
export default {
  components: {
    TopBar,
    PersonnelList
  },
  data () {
    return {
      count: 0,
      teamSpace: false,
      spaceInfo: {},
      editDialog: false,
      PictureUrlService: '',
      errorImg: 'this.src="' + require('../assets/project.png') + '"',
      isPersonal: false,
      userPermiss: {},
      editNameDialog: false,
      name: '',
      logo: require('@/assets/officialwebsite/logo.png'),
      id: 0,
      isEdit: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapMutations('logo', {
      initLogo: 'initLogo'
    }),
    getUserPermiss () {
      // this.userPermiss = JSON.parse(localStorage.getItem('currentSpace'))
      // this.id = localStorage.getItem("eleSpaceId")
      // console.log(this.id)
    },
    async init () {
      // let id =await
      this.id = this.$store.state.spaceInfo.inSpaceId
      if (!this.id) { return }
      this.$axios.get(`/api/space/getspacebyid?SpaceId=${this.id}`).then(res => {
        console.log(res)
        this.userPermiss = res
        this.count = res.numberAccount
        if (res.type == 1) {
          // true代表是企业
          this.teamSpace = true
        }
        this.spaceInfo = res
        if (!this.spaceInfo.logoPictureStr) {
          this.spaceInfo.logoPictureStr = this.logo
        }
        console.log(this.spaceInfo)
        if (this.spaceInfo.isExpire || this.spaceInfo.statu < 0 || localStorage.getItem('allLock') == 1) {
          this.isEdit = false
        }
      })
      // let res = JSON.parse(localStorage.getItem('currentSpace'))
      // console.log(res)
      // this.count = res.numberAccount
      // if (res.type == 1) {
      // 	// true代表是企业
      // 	this.teamSpace = true
      // }
      // this.spaceInfo = res
      // if (!this.spaceInfo.logoPictureStr) {
      // 	this.spaceInfo.logoPictureStr = this.logo
      // }
      // console.log(this.spaceInfo)
      // if(this.spaceInfo.isExpire||this.spaceInfo.statu < 0||localStorage.getItem("allLock")==1){
      // 	this.isEdit = false
      // }
    },
    uploadImg () {
      this.editDialog = true
      this.PictureUrlService = this.spaceInfo.logoPictureStr
      formData = new FormData()
      if (this.PictureUrlService) {
        var index = this.PictureUrlService.indexOf('Doc/Project')
        var url = this.PictureUrlService.substring(index, this.PictureUrlService.length)
        formData.append('CoverPicture', url)
      }
    },
    UpdateSpaceName () {
      if (!this.name) {
        this.$message({
          type: 'warning',
          message: '请输入企业名称'
        })
        return
      }
      this.$axios.get(`/api/Space/UpdateSpaceName?name=${this.name}&spaceId=${this.spaceInfo.id}`).then(res => {
        this.editNameDialog = false
        this.init()
      })
    },
    uploadPicture (file) {
      formData.append('FormFile', file.file)
    },
    getPicture (file, fileList) {
      this.PictureUrlService = file.url
    },
    editName () {
      this.editNameDialog = true
      this.name = this.spaceInfo.name
    },
    changeImg () {
      if (this.PictureUrlService) {
        formData.append('Id', this.spaceInfo.id)
        formData.append('statu', 4)
        this.$axios.post('/api/Project/UpdateCoverPicture', formData).then(res => {
          console.log(res)
          this.$message({
            type: 'success',
            message: '上传成功'
          })
          this.spaceInfo.logoPictureStr = res.headPortrait
          if (this.spaceInfo.id == localStorage.getItem('eleSpaceId')) {
            this.initLogo(res.headPortrait)
          }
          this.editDialog = false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
	.ownProjectType {
		/deep/.el-upload--picture-card{
			width: 132px;
			height: 62px;
			line-height: 62px;
		}
		p {
			text-align: left;
		}

		.perSpaceClass {
			justify-content: center !important;
		}

		.ownProjectType-container {
			// background-color: #000000;
			display: flex;
			justify-content: space-between;
			// height: calc(100vh - 80px);
			height: 877px;
			width: 88%;
			margin: auto;
			background-color: #e5e5e5;
			padding: 38px 25px 15px 25px;
			box-sizing: border-box;

			.container-left {
				width: 28%;
				height: 100%;
				// background-color: #000000;
				background-color: #fff;
				box-shadow: 0 0 5px 5px #d9d9d9;
				padding: 15px;
				box-sizing: border-box;
				overflow-y: auto;
				.text-left{
					font-size: 20px;
					font-weight: 400;
					color: #000000;
				}
				.text-right{
					font-size: 20px;
					text-align: right;
				}
				.disable{
					color: #acacac;
				}
				.brand-container {
					p:nth-child(2) {
						font-size: 18px;
						padding: 8px 0;
					}
					.fix-container {
						display: flex;
						justify-content: space-between;
						// margin-top: 10px;
						padding: 20px 0;
						border-bottom: 1px solid #E5E5E5;

						img {
							width: 400px;
						}

						.img {
							width: 200px;
							height: 100px;
							border-radius: 5px;

						}

						.el-button--primary {
							background: #475065;
							border: none;
							height: 40px;
						}
					}
				}

				.account-container {
					padding:0 0 20px 0;
					border-bottom: 1px solid #E5E5E5;

					>p {
						font-size: 16px;
					}
					.companyName{
						display: flex;
						justify-content: space-between;
						padding:0 0 20px 0;
						border-bottom: 1px solid #E5E5E5;
						.el-button--primary {
							margin-top: 14px !important;
							background-color: #475065;
							border: none;
						}
						.name{
							font-size: 20px;
						}
						.fun{
							text-align: right;
						}
					}
					.item {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 15px 0;
					}
				}

				.storage-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid #E5E5E5;
					padding: 20px 0;
				}

				.num-container {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid #E5E5E5;
					padding: 20px 0;
				}
			}
		}

		.btn {
			.el-button--primary {
				background-color: #475065;
				border: none;
			}
		}
	}
</style>
