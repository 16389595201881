<template>
    <div class="container-right">
		<!-- <p>成员</p> -->
		<div class="project-overview">
			<div class="item">
				<div class="overview-left">
					<p style="text-align: left;">公司成员</p>
					<p><span>{{seatEnterprise}}/{{count}}</span></p>
				</div>
				<div class="overview-right">
					<el-button type="primary" @click="pullUser(1)" v-if="teamSpace&&isEdit">邀请公司成员</el-button>
				</div>
			</div>
			<div class="item">
				<div class="overview-left">
					<p style="text-align: left;">外部成员</p>
					<p><span>{{(userList.length-seatEnterprise)<0?0:userList.length-seatEnterprise}}</span></p>
				</div>
				<div class="overview-right">
					<el-button v-if="isEdit" type="primary" @click="pullUser(0)">邀请外部成员</el-button>
					<!-- <el-button type="primary" @click="back">返回</el-button> -->
				</div>
			</div>
		</div>
		<div class="project-personnel">
			<div class="client-container">
				<div class="personnelList">
	            	<ul>
	            		<span v-for="(item,index) of userList" :key="index">
							<li v-if="item.seatEnterprise==spaceId || item.spaceOwnUser > 0">
	            			    <span>{{item.firstChart}}</span>
	            			    <span>{{item.nickName}}</span>
								<span @click="copyText(item.userName)">{{item.userName}}</span>
	            			    <span class="li3" v-if="item.spaceOwnUser == 1">拥有者</span>
								<span  v-else @click="updateUserSpaceRoleMth(item)" class="pointer li3">{{item.spaceRoleList[0].spaceRoleName}}<i class="el-icon-caret-bottom"></i></span>
	            			    <span class="el-icon-close pointer" @click="deleteUser(item)"></span>
	            		    </li>
						</span>
	            	</ul>
	            </div>
			</div>
			<div class="member-container">
				<div class="personnelList">
	            	<ul>
	            		<span v-for="(item,index) of userList" :key="index">
							<li v-if="item.seatEnterprise !=spaceId && item.spaceOwnUser == 0">
	            			    <span>{{item.firstChart}}</span>
	            			    <span>{{item.nickName}}</span>
								<span @click="copyText(item.userName)">{{item.userName}}</span>
								<!-- curUserOwnId == curUserID && curUserStatu>item.spaceRoleList[0].statu -->
	            			    <span  v-if="spacePermiss.isAdmin||spacePermiss.isSmartAdmin||spacePermiss.isSpaceOwner"  @click="updateUserSpaceRoleMth(item)" class="pointer li3">{{item.spaceRoleList[0].spaceRoleName}}<i class="el-icon-caret-bottom"></i></span>
								<span v-else >{{item.spaceRoleList[0].spaceRoleName}}</span>
	            			    <span class="el-icon-close pointer" @click="deleteUser(item)"></span>
	            		    </li>
						</span>
	            	</ul>
	            </div>
			</div>
		</div>
		<el-dialog :title="diaTitle" :visible.sync="dialogVisible" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

				<el-form-item label="用户名" prop="UserName">
					<el-input v-model="ruleForm.UserName"></el-input>
				</el-form-item>
				<div class="inline-box">
					<el-form-item label="空间角色" prop="SpaceRoleId">
						<el-select v-model="ruleForm.SpaceRoleId" placeholder="请选择">
							<el-option v-for="item in jurisdiction"  :label="item.spaceRoleName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="项目">
						<el-select v-model="ruleForm.ProjectId" placeholder="请选择">
							<el-option v-for="item in pulluserprojectList" :key="item.id" :label="item.projectName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item> -->
				</div>
			</el-form>
			<p style="text-align: right;">
				<el-button type="danger" @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" icon="el-icon-success" @click="addMember">确定</el-button>
			</p>
		</el-dialog>
		<!-- 修改用户角色ID -->
		<!-- {{isEdit}} -->
		<el-dialog title="修改用户" :visible.sync="updateUserSpaceRole" width="30%" :close-on-click-modal="false">
			<div style="text-align:right;">
				<el-form ref="ruleForm3" :model="curFixData" :rules="fixUserRoleRules" label-width="100px" label-position="left" style="text-align: left;">
					<el-form-item label="用户名" >
						<el-input  v-model="curFixData.name" placeholder="用户名称" disabled />
					</el-form-item>

					<el-form-item label="角色" prop="spaceId">
						<el-select  v-model="curFixData.spaceId" placeholder="请选择">
							<el-option
							  v-for="item in SpaceRoleListHavePermiss "
							  :key="item.id"
							  :label="item.spaceRoleName"
							  :value="item.id">
							</el-option>
						 </el-select>
					</el-form-item>
					<el-form-item label="身份变更" >
						<el-radio-group v-model="curFixData.type">
                          <el-radio :label="1">公司成员</el-radio>
                          <el-radio :label="2">外部成员</el-radio>
                        </el-radio-group>
					</el-form-item>
				</el-form>
				<el-button type="danger" @click="updateUserSpaceRole=false">取消</el-button>
				<el-button type="primary" @click="updateUserSpaceRoleAPI">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: ['count', 'teamSpace', 'isEdit'],
  data () {
    return {
      spaceId: 0,
      userList: [],
      curUserID: localStorage.getItem('USER_ID'),
      curUserStatu: 0,
      curUserOwnId: 0, // 企业拥有者id
      seatEnterprise: 0,
      dialogVisible: false,
      editDialog: false,
      PictureUrlService: '',
      updateUserSpaceRole: false,
      SpaceRoleListHavePermiss: [],
      ruleForm: {
        UserName: '',
        SpaceRoleId: '',
        ProjectId: '',
        CusMember: 0
      },
      userInfo: {},
      curFixData: {
        name: '',
        spaceId: 0,
        userId: 0,
        type: 0
      },
      fixUserRoleRules: {
        UserName: {
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        },
        spaceId: {
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }
      },
      rules: {
        UserName: {
          required: true,
          message: '请填写用户名',
          trigger: 'blur'
        },
        juris: {
          required: true,
          message: '请选择所属项目',
          trigger: 'change'
        },
        SpaceRoleId: {
          required: true,
          message: '请选择空间角色',
          trigger: 'change'
        }
      },
      jurisdiction: [],
      pulluserprojectList: [],
      sid: 0,
      diaTitle: '邀请公司成员'
    }
  },
  created () {
    this.sid = this.$store.state.spaceInfo.inSpaceId
    // localStorage.setItem("eleSpaceId",this.sid)
  },
  mounted () {
    this.spaceId = this.$store.state.spaceInfo.inSpaceId
    // this.spaceId = localStorage.getItem('eleSpaceId')
    this.init()
  },
  computed: {
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })
  },
  methods: {
    // 对数据进行处理，拿出首字符并且根据首字符进行排序
    init () {
      this.$axios.get(`/api/User/GetSpaceUserList?SpaceId=${this.sid}`).then(res => {
        console.log(res)
        this.userList = res
				    this.userList.forEach(item => {
				    	item.firstChart = item.nickName.slice(0, 1)
				    })
        var spaceId = this.sid
        this.seatEnterprise = this.userList.filter(n => n.seatEnterprise == spaceId).length + 1
				    function compare (val1, val2) {
				    	return val1.firstChart.toLowerCase() > val2.firstChart.toLowerCase()
				    };
				    this.userList = this.userList.sort(compare)
        var curUser = this.userList.findIndex(n => n.userId == this.curUserID)
        var curOwnUser = this.userList.findIndex(n => n.spaceOwnUser == 1)
        if (curOwnUser > -1) {
          this.curUserOwnId = this.userList[curOwnUser].userId
        }
        if (curUser > -1) {
          if (this.userList[curUser].spaceRoleList.length > 0) {
            this.curUserStatu = this.userList[curUser].spaceRoleList[0].statu
          }
        } else {
          // 能登陆进来，并且空间用户列表中没有此用户，说明此用户为超级管理员或企业拥有者
          this.curUserStatu = 100
        }
      })
				 // 空间角色
      // this.$axios.get("/api/SpaceRole/GetAllSpaceRole").then(res => {
      // 	this.SpaceRoleList = res
      // 	this.SpaceRoleListHavePermiss = res
      // })
      // this.$axios.get(`/api/spacerole/getallspacerolebyid?SpaceId=${this.sid}`).then(res => {
      // 	console.log(res)
      // 	this.SpaceRoleList = res
      // 	this.SpaceRoleListHavePermiss = res
      // })
      console.log(this.sid)
      this.$axios.get('/api/Project/GetProjectBySpaceId?spaceId=' + this.sid).then(res => {
        var projectList = res
        this.pulluserprojectList = []
        for (var i = 0; i < projectList.length; i++) {
          projectList[i].positionNum = i + 1
          projectList[i].projectTypeId = projectList[i].id
          projectList[i].id = 'card' + projectList[i].id
          projectList[i].name = projectList[i].projectTypeName
          if (projectList[i].projectListDto && projectList[i].projectListDto.length > 0) {
            this.pulluserprojectList = this.pulluserprojectList.concat(projectList[i].projectListDto)
          }
        }
      })
    },
    pullUser (type) {
      if (type) {
        this.diaTitle = '邀请公司成员'
      } else {
        this.diaTitle = '邀请外部成员'
      }
      this.dialogVisible = true
      this.ruleForm.ProjectId = ''
      this.ruleForm.UserName = ''
      this.ruleForm.SpaceRoleId = ''
      this.ruleForm.CusMember = type
      this.jurisdiction = []
      this.$axios.get(`/api/spacerole/getallspacerolebyid?SpaceId=${this.sid}`).then(res => {
        console.log(res)
        this.SpaceRoleList = res
        this.SpaceRoleListHavePermiss = res
        // 如果是企业拥有者，可以修改任何权限
				    if (this.curUserOwnId == this.curUserID) {
          this.jurisdiction = res
			    	} else {
          for (var i = 0; i < res.length; i++) {
				    		// 如果角色权限小于该用户的权限，则可显示
				    		if (this.curUserStatu > res[i].statu &&　res[i].spaceRoleName != '客户') {
				    			this.jurisdiction.push(res[i])
				    		}
				    	}
        }
      })
    },
    addMember () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          var pullInfo = this.$utils.deepClone(this.ruleForm)
          pullInfo.ProjectId = pullInfo.ProjectId == '' ? 0 : pullInfo.ProjectId
          pullInfo.spaceId = this.sid
          this.$axios.post('/api/Space/PullSpaceOrProjectUser', pullInfo).then(res => {
				        	this.init()
            this.$message({
              type: 'success',
              message: '邀请用户成功'
            })
            this.dialogVisible = false
            this.ruleForm.ProjectId = ''
				        })
        } else {
          return false
        }
      })
    },
    back () {
      this.$router.push('/projectType')
    },
    updateUserSpaceRoleMth (item) {
      if (!this.isEdit) {
        this.$message({
          type: 'error',
          message: '服务已到期，不能进行操作！'
        })
        return
      }
      this.userInfo = item
      if (item.userId == this.curUserID) {
        this.$message({
          type: 'warning',
          message: '不能修改自己信息！'
        })
        return
      }
      // 如果是企业拥有者则不能修改
      if (item.spaceOwnUser == 1) {
        this.$message({
          type: 'warning',
          message: '不能修改企业拥有者！'
        })
        return
      }
      console.log(this.userInfo)
      console.log(this.sid)
      if (this.userInfo.seatEnterprise == this.sid) {
        this.curFixData.type = 1
      } else {
        this.curFixData.type = 2
      }
      // 如果是企业拥有者，可以修改任何权限
      if (this.curUserOwnId == this.curUserID) {
        // 空间角色
				    this.$axios.get(`/api/spacerole/getallspacerolebyid?SpaceId=${this.sid}`).then(res => {
          this.SpaceRoleList = res
          this.SpaceRoleListHavePermiss = res
          var index = this.SpaceRoleListHavePermiss.findIndex(n => n.spaceRoleName == '客户')
          if (index > -1) {
            this.SpaceRoleListHavePermiss.splice(index, 1)
          }
          this.curFixData.name = item.nickName
				        this.curFixData.userId = item.userId
				        this.curFixData.spaceId = item.spaceRoleList[0].id
				        this.updateUserSpaceRole = true
        })
      } else {
        if (this.curUserStatu > item.spaceRoleList[0].statu) {
          // 空间角色
				        this.$axios.get(`/api/spacerole/getallspacerolebyid?SpaceId=${this.sid}`).then(res => {
				    		this.SpaceRoleListHavePermiss = []
				    		if (this.curUserOwnId == this.curUserID) {
				    			this.SpaceRoleListHavePermiss = res
				    		} else {
				    		    for (var i = 0; i < res.length; i++) {
				    		    	// 如果角色权限小于该用户的权限，则可显示
				    		    	if (this.curUserStatu > res[i].statu) {
				    		    		this.SpaceRoleListHavePermiss.push(res[i])
				    		    	}
				    		    }
				    		}
            var index = this.SpaceRoleListHavePermiss.findIndex(n => n.spaceRoleName == '客户')
						    if (index > -1) {
						    	this.SpaceRoleListHavePermiss.splice(index, 1)
						    }
				            this.curFixData.name = item.nickName
				            this.curFixData.userId = item.userId
				            this.curFixData.spaceId = item.spaceRoleList[0].id
				            this.updateUserSpaceRole = true
				        })
				    } else {
				    	this.$message({
				    		type: 'warning',
				    		message: '您没有权限修改此用户！'
				    	})
				    }
      }
    },
    updateUserSpaceRoleAPI () {
      var type = 0
      console.log(this.userInfo.seatEnterprise)
      if (this.userInfo.seatEnterprise == 0 && this.curFixData.type == 1) {
        type = 1
      } else if (this.userInfo.seatEnterprise == this.sid && this.curFixData.type == 2) {
        type = 2
      }
      // localStorage.setItem("eleSpaceId",this.$store.state.spaceInfo.inSpaceId)
      this.$axios.get(`/api/user/updatespaceroleid?userId=${this.curFixData.userId}&spaceRoleId=${this.curFixData.spaceId}&Statu=${type}&SpaceId=${this.$store.state.spaceInfo.inSpaceId}`).then(res => {
        console.log(res)
        if (res == '更新成功') {
          for (var key in this.userList) {
            if (this.userList[key].userId == this.curFixData.userId) {
              console.log(this.SpaceRoleList)
              var findValue = this.SpaceRoleList.find(item => {
                return item.id == this.curFixData.spaceId
              })
              this.userList[key].spaceRoleList[0] = findValue
              if (type == 1) {
                this.userList[key].seatEnterprise = this.sid
                this.seatEnterprise += 1
              } else if (type == 2) {
                this.userList[key].seatEnterprise = 0
                this.seatEnterprise -= 1
              }
            }
          }
          this.updateUserSpaceRole = false
          this.$message({
            type: 'success',
            message: '修改成功'
          })
        }
      })
    },
    change () {

    },
    deleteUser (item) {
      if (!this.isEdit) {
        this.$message({
          type: 'error',
          message: '服务已到期，不能进行操作！'
        })
        return
      }
      if (item.userId == this.curUserID) {
        this.$message({
          type: 'warning',
          message: '不能删除自己！'
        })
        return
      }
      // 如果是企业拥有者则不能修改
      if (item.spaceOwnUser == 1) {
        this.$message({
          type: 'warning',
          message: '不能删除企业拥有者！'
        })
        return
      }
      // 企业拥有者，可以修改任何权限
      if (this.curUserOwnId == this.curUserID || this.curUserStatu > item.spaceRoleList[0].statu) {
        this.$confirm(`此操作将删除该企业用户，同时移出该企业下用户所有所在的项目, 是否继续?`, '提示', {
				        	confirmButtonText: '确定',
				        	cancelButtonText: '取消',
				        	type: 'warning'
				        }).then(() => {
				        	this.$axios.get('/api/Space/DeleteSpaceUser?UserId=' + item.userId + '&SpaceId=' + this.sid).then(res => {
				        		this.$message({
				        			type: 'success',
				        			message: '删除成功!'
				        		})
				        		this.init()
				        	})
				        })
      } else {
				    this.$message({
				    	type: 'warning',
				    	message: '您没有权限删除此用户！'
				    })
      }
    },
    copyText (text) {
      var input = document.createElement('input') // js创建一个input输入框
      input.value = text // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
      document.body.appendChild(input) // 将输入框暂时创建到实例里面
      input.select() // 选中输入框中的内容
      document.execCommand('Copy') // 执行复制操作
      document.body.removeChild(input) // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
				    message: '复制成功',
				    type: 'success'
      })
    }
  }

}
</script>

<style scoped lang="less">
    .container-right {
    	width: 71%;
    	height: 100%;
    	background-color: #fff;
    	box-shadow: 0 0 5px 5px #d9d9d9;
    	padding: 15px;
    	box-sizing: border-box;
    	overflow-y: auto;
    	>p{
    		padding: 10px;
			text-align: left;
			font-size: 20px;
    	}
    	.project-overview{
    		display: flex;
    		width: 100%;
    		justify-content: space-between;
    		align-items: center;
    		padding: 0 20px;
    		box-sizing: border-box;
    		border-bottom: 1px solid #E5E5E5;
			.item{
				display: flex;
				width: 45%;
				justify-content: space-between;
				.overview-left{
					p:nth-child(1){
						font-size: 20px;
						padding: 0 10px 20px 10px;
					}
					p:nth-child(2){
						font-size: 20px;
						padding: 0 10px 20px 10px;
						span{
							font-size: 30px;
							margin-right: 10px;
							letter-spacing: 5px;
						}
					}
				}
				.overview-right{
					padding-top: 23px;
					.el-button--primary {
						background: #475065;
						border: none;
						// height: 40px;
					}
				}
			}

    	}
    	.project-personnel{
    		padding: 10px;
    		// border-bottom: 1px solid #000000;
    		display: flex;
    		.client-container{
    			width: 50%;
    			// height: 700px;
    			border-right: 1px solid #e5e5e5;
    			height: 600px;
    			// overflow-y: auto;
    			padding: 10px 10px 0 10px;
    			box-sizing: border-box;
    		}
    		.member-container{
    			width: 49%;
    			height: 600px;
    			height: 600px;
    			// overflow-y: auto;
    			padding: 10px 10px 0 10px;
    			box-sizing: border-box;
    		}
    	}
    }
	.el-button--primary {
		background: #2F374A;
  	}

	.personnelList {
		height: 100%;
		overflow-y: auto;

		.pointer {
			cursor: pointer;
		}
		ul {
			margin: 10px;
			border-top: 1px solid #E5E5E5;

			li {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 5px 10px;
				align-items: center;
				border-bottom: 1px solid #e5e5e5;
				box-sizing: border-box;
				color: #838383;
				font-size: 14px;
				span{
					display: inline-block;
				}
				>span:nth-child(1) {
					display: inline-block;
					width: 30px;
					height: 30px;
					line-height: 30px;
					background-color: #475065;
					color: #fff;
					border-radius: 50%;
					font-size: 12px;
					margin-right: 20px;
				}
				>span:nth-child(2){
					text-align: left;
					width: 35%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				>span:nth-child(3){
					text-align: left;
					width: 40%;
					cursor: pointer;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-right: 10px;
				}
				>span:nth-child(4) {
					text-align: left;
					width: 60px;
				}
			}
		}
	}
</style>
